// Typography: general, headlines

html {
    box-sizing: border-box;
    font-size: 100%;
    overflow-x: hidden;
}

// Set app background color
:root {
    --app-bg-color: #dedede;
}


// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    background-color: $white;
    font-family: $font;
    font-weight: normal;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.no-scrolling {
    height: 100%;
    overflow: hidden;
}

hr {
    margin: 24px 0;
}

.hr--thin {
    margin: 16px 0;
}

.hr--bold {
    border-top: 3px solid;
}

.page {
    background-color: $white;
    margin: 0 auto;
}

.page-wrapper {
    margin: 0 auto;
    max-width: 1440;
    position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font;
    font-weight: bold;
}

h1 {
    font-size: rem-calc(44);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(76);
    }
}

h2 {
    font-size: rem-calc(40);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(60);
    }
}

h3 {
    font-size: rem-calc(34);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(52);
    }
}

h4 {
    font-size: rem-calc(32);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(44);
    }
}

h5 {
    font-size: rem-calc(32);
}

h6 {
    font-size: rem-calc(26);
}

.form-control:focus {
    border-color: $primary !important;
    box-shadow: none !important;
}

.custom-select {
    &:focus {
        border-color: $primary;
    }
}

/* stylelint-disable */
.dropdown-item {
    @include media-breakpoint-down(md) {
        &:hover,
        &:focus,
        &.active,
        &:active {
            background-color: transparent !important;
            color: $primary !important;
        }

        a {
            &:hover,
            &:focus,
            &.active,
            &:active {
                background-color: transparent !important;
                color: $primary !important;
            }
        }
    }
}
