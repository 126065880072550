.product-breadcrumb,
.breadcrumbs-wrapper {
    border-bottom: 1px solid $border-divider-light;
    margin-bottom: 0;
    margin-left: 0;
}

.breadcrumbs-wrapper {
    margin-bottom: rem-calc(24);
}

