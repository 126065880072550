@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.form-nav .nav-tabs .nav-link {
    color: $grey8;
}

.form-nav .nav-tabs .nav-link.active,
.form-nav .nav-tabs .nav-link.active:focus,
.form-nav .nav-tabs .nav-link.active:hover {
    background-color: $white;
    color: $grey8;
}

.nav-tabs .nav-link.active {
    background-color: transparent;
}

@include media-breakpoint-up(lg) {
    .form-nav .nav-item > .nav-link {
        color: $grey8;
    }
}

.nav-tabs {
    border-color: $border-divider-default;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-color: $border-divider-default $border-divider-default $white;
    color: $link-hover;
}

.nav-tabs .nav-link:hover {
    border-color: $border-divider-default $border-divider-default $border-divider-default;
    color: $link-hover;
}
