@import "../utilities/swatch";
@import "productCommon";
@import "quickView";

.product-tile {

    border: 0;
    margin-bottom: 0;
    position: relative;

    @include media-breakpoint-down(md) {
        min-height: 23.4375em;
    }

    @include media-breakpoint-down(sm) {
        min-height: 19.6875em;
    }

    @include media-breakpoint-down(xs) {
        min-height: 13.4375em;
    }

    .tile-body {
        padding: 0.625em 0 1.875em;

        /* stylelint-disable */
        .color-swatches {
            min-height: 2.25em;

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .price {
            font-size: rem-calc(13);
            margin-bottom: rem-calc(5);

            @include media-breakpoint-down(lg) {
                font-size: rem-calc(13);
            }

            .tiered {
                font-size: rem-calc(13);

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pdp-link {
            line-height: 1.2;
            margin: 3px 0;
            letter-spacing: 0.5px;

            a,
            .shop-product-title {
                font-size: rem-calc(14);
                text-decoration: none;
                color: $black !important;
                font-weight: 700;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .ratings {
            font-size: 0.9em;
        }
    }

    .image-container {
        position: relative;

        .quickview {
            bottom: 1rem;
            position: absolute;
            right: 1rem;

            i {
                &.fa-expand {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.fa-circle {
                    color: rgba(255, 255, 255, 0.7);
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }

        a {
            display: block;

            .tile-image {
                display: block;
                height: auto;
                margin: 0 auto;
                max-width: 100%;
                max-height: 100%;
                width: auto;
            }
        }
    }

    .swatches {
        a {
            text-decoration: none;
        }
    }

    .swatch-circle {
        @include swatch-square();

        height: 28px;
        width: 28px;
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }

    .select-cbp-container {
        margin-bottom: auto;
        margin-top: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 0;
    }

    .selected-pid {
        border: 1px solid $grey3;
        padding: 10px;
        margin-top: 10px;

        .bonus-product-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .bonus-product-price {
        margin-top: 1em;
        text-align: center;
    }
}

.visual-refinebar {
    border: rem-calc(1) solid $border-divider-light;
    margin-bottom: 1.25em;
    padding: 0.625em 0.625em 0.625em 0.625em;
    text-align: center;

    .header,
    .values {
        padding: 0;

        @include media-breakpoint-only(sm) {
            li {
                display: inline-block;
            }
        }
    }

    ul {
        overflow: auto;

        li {
            padding-left: 10px;

            button {
                border: none;
                color: #{var(--skin-link-color-1)};
                background-color: transparent;
            }
        }
    }

    .product-type-slider {
        list-style-type: none;
        padding-left: 0;
        margin: 0;

        .visual-refinement {
            display: inline-block;
            padding-left: 0;

            button {
                padding: 0;
                border: none;
                background: transparent;
            }

            img {
                width: 100%;
                max-width: 100%;
            }

            &[data-selected="true"] {
                border: 1px solid $border-color;
            }

            &[data-selected="false"] {
                .refinement-checkbox {
                    visibility: hidden;
                }
            }
        }

        &.disabled {
            opacity: 0.5;
        }

        .slick-slide {
            margin: 0 5px;
        }

        .product-name {
            font-size: rem-calc(12);
        }
    }

    .fa-check-square::before {
        background: $border-color url("../../images/icons/check-icon.svg") no-repeat center;
        background-size: rem-calc(18);
        border: 1px solid $border-color;
        border-radius: 0;
        display: inline-block;
        height: rem-calc(18);
        width: rem-calc(18);
        content: "";
        position: relative;
        top: 8px;
        left: -34px;
        border-radius: 99%;

        @include media-breakpoint-only(md) {
            height: rem-calc(18);
            width: rem-calc(18);
        }
    }
}

.promotional-message {
    color: #a17d23;
    font-weight: 600;
}