$arrow-height: 1.25em; /* 20/16 */

.info-icon {
    background: inherit;
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    position: relative;

    .icon {
        background-color: #{var(--skin-primary-color-1)};
        border-radius: 1.25rem;
        color: #fff;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        width: 1.5625rem;
    }

    .tooltip {
        animation: fade-in 0.5s linear forwards;
        background-color: $grey6;
        border-radius: $border-radius;
        bottom: 100%;
        color: $white;
        font-size: 0.928rem;
        left: 50%;
        margin-left: 4px;
        max-width: 15rem;
        min-width: 20rem;
        padding: 0.312rem;
        position: absolute;
        transform: translate(-50%, calc(-1 * $arrow-height / 2));

        /* stylelint-disable */
        @keyframes fade-in {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }

        &::before {
            bottom: -$arrow-height;
            content: " ";
            display: block;
            height: $arrow-height; /* 20/16 */
            left: 0;
            position: absolute;
            width: 100%;
        }

        &::after {
            border-left: solid transparent calc($arrow-height / 2); /* 10/16 */
            border-right: solid transparent calc($arrow-height / 2); /* 10/16 */
            border-top: solid $grey6 calc($arrow-height / 2);
            bottom: calc(-1 * $arrow-height / 2);
            content: " ";
            height: 0;
            left: 50%;
            margin-left: -13px;
            position: absolute;
            width: 0;
        }
    }
}
