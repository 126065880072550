.veil {
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 100;

    .underlay {
        background-color: $black;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

$spinner-size: 80px;

.spinner {
    animation: sk-rotate 2s infinite linear;
    height: $spinner-size;
    left: 50%;
    margin-left: calc($spinner-size / 2 * -1);
    margin-top: calc($spinner-size / 2 * -1);
    position: absolute;
    text-align: center;
    top: 50%;
    width: $spinner-size;
}

.dot1,
.dot2 {
    animation: sk-bounce 2s infinite ease-in-out;
    background-color: $white;
    border-radius: 100%;
    display: inline-block;
    height: 60%;
    position: absolute;
    top: 0;
    width: 60%;
}

.dot2 {
    animation-delay: -1s;
    bottom: 0;
    top: auto;
}

@keyframes sk-rotate {
    100% { transform: rotate(360deg); }
}

@keyframes sk-bounce {
    0%,
    100% { transform: scale(0); }
    50% { transform: scale(1); }
}
