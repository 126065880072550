@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";
@import "utilities/mixins";
@import "~plugin_giftregistry/giftIcon";

.minicart {
    position: relative;

    h1 {
        font-family: $font;
        font-size: 1rem;
    }

    .cart {
        padding: rem-calc(10);

        > div > .row { /* stylelint-disable-line */
            margin: rem-calc(0 -10);
        }
    }

    .remove-btn {
        background-color: transparent;
        border: 0;
        color: $black;
        float: right;
        font-size: 1.625em;
        margin-top: -0.313em;
        padding: 0;
    }

    .product-summary {
        margin-right: -0.938em;
        max-height: 21.875em;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 0.938em;
    }

    .card-body {
        padding: 0.625em;
    }

    .quantity-label {
        font-size: 0.813em;
    }

    .quantity {
        width: 100%;
    }

    /* stylelint-disable */
    .popover {
        border-radius: 0;
        display: none;
        left: auto;
        max-width: 23.44rem;
        min-height: 22.7rem;
        min-width: 23.44rem;
        right: 0;
        top: 100%;
        font-family: $font;

        &::before {
            left: auto;
            right: 15px;
        }

        &::after {
            left: auto;
            right: 16px;
        }

        &.show {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }

    .minicart-footer {
        border-top: 1px solid $grey3;
    }

    .estimated-total {
        margin-top: 0.625em;
    }

    .sub-total-label {
        font-size: 1em;
        font-weight: 600;
    }

    .sub-total {
        font-size: 1em;
        font-weight: 600;
    }

    .line-item-divider {
        margin: 0.625em -0.625em 0.625em -1.325em;
    }

    .line-item-name {
        width: 90%;
    }

    .custom-select {
        @include select-box();
    }
}

.hide-link-med {

    @include media-breakpoint-only(sm) {
        display: none;
    }
}

.hide-no-link {

    @include media-breakpoint-up(md) {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.product-summary {
    .col-12 {
        padding: 0;
    }
}

.minicart-footer {
    .estimated-total {
        padding: rem-calc(15 0 0);
    }
}

.price {
    .strike-through {
        margin: 0;
    }

    .no-strike-through {
        text-decoration: unset;
    }
}
