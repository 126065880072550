 /* stylelint-disable */
.modal-header {
    color: $black;

    .modal-title {
        flex: 1;
        text-align: left;
    }

    .button.close {
        position: absolute;
        right: rem-calc(16);
        top: rem-calc(16);
        z-index: 1;
    }
}

 .modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
}

.modal-footer {
    .btn-primary {
        margin-left: 0;
    }
}

input[placeholder] {
    text-overflow: ellipsis;
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
    color: $text-secondary;
}

.hide-order-discount {
    display: none !important;
}

.hide-shipping-discount {
    display: none !important;
}

.hide-loyalty-vouchers {
    .loyalty-points-section {
        margin-top: 0 !important;
    }

    .accordion {
        display: none !important;
    }
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url("../images/storelocator.jpg");
    margin-bottom: 0.625em;
}

.error-message {
    margin-bottom: 3.125rem;
    margin-top: 3.125rem;
}

.alert-danger {
    .error-message-text{
        margin-bottom: 0;
    }
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}

.skip {
    background: $white;
    left: 0;
    overflow: hidden;
    padding: 1em 1.5em;
    position: absolute;
    top: -4.2em;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    background: $white;
    height: auto;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in-out;
    width: auto;
    z-index: 10000000;
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.card {
    border-radius: 0 !important;
    border-color: $border-divider-light !important;
    margin-bottom: rem-calc(16);
    background-color: transparent;

    .card-header {
        border-color: $border-divider-light;
    }
}

.forgot-password {
    a {
        margin-bottom: rem-calc(8);
        margin-top: rem-calc(8);
        display: block;

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(10);
        }
    }
}

.hide {
    display: none;
}

hr {
    border-color: $border-divider-light !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #002E6D !important;
}

.modal-body {
    padding: rem-calc(16);
    font-size: rem-calc(14);
    color: $black;

    .coupon-to-remove,
    .product-to-remove {
        font-weight: 700;
        margin-top: rem-calc(16);
    }
}

.modal-content {
    border: 0;
    border-radius: 0;
}

.modal {
    padding: 0 !important;

    .modal-header {
        color: #000;
        font-size: 32px;
        text-align: left;
        justify-content: start;
        background-color: transparent;
        border-bottom: 1px solid $grey3;
    }

    .modal-body {
        font-size: rem-calc(16);
    }
}

.close {
    background: transparent;
    position: absolute;
    right: rem-calc(10);
    top: rem-calc(5);

    .icon-close {
        background-image: url("../images/icons/close-icon.svg");
        content: "";
        display: inline-block;
        font-style: normal;
        height: rem-calc(17);
        width: rem-calc(17);

        &.icon-small {
            background-image: url("../images/icons/cross-small.svg");
            height: rem-calc(24);
            width: rem-calc(24);
        }
    }
}

.tracking-consent {
    padding-top: rem-calc(16);
}

.recommendation-slider {
    margin: 0 auto;
    position: relative;
    @include media-breakpoint-up(lg) {
        width: 85%;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: var(--swiper-navigation-sides-offset, -15px);
        @include media-breakpoint-up(lg) {
            right: var(--swiper-navigation-sides-offset, -7%);
        }
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: var(--swiper-navigation-sides-offset, -15px);
        @include media-breakpoint-up(lg) {
            left: var(--swiper-navigation-sides-offset, -7%);
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: $black;
        background-color: $primary;
        top: var(--swiper-navigation-top-offset, 35%);
    }
}
h1, .h1 {
    font-size: rem-calc(32);
    font-weight: 700;
    @include media-breakpoint-up(lg) {
        font-size: rem-calc(40);
    }
}
.cart-recomm {
    text-align: center;
    margin: 0;
    padding: rem-calc(40 0 20);
    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(32);
        padding: rem-calc(40 0 24);
    }
}

.text-transform-none {
    text-transform: none !important;
}

.price {
    .sales {
        font-weight: 700;
    }
}

.swiper-button-prev::after,
.swiper-rtl .swiper-button-next::after {
    background-image: url("../../images/arrow-slider-left.svg") !important;
    content: "";
    display: inline-block;
    height: 22px;
    width: 22px;
}

.swiper-button-next::after,
.swiper-rtl .swiper-button-prev::after {
    background-image: url("../../images/arrow-slider-right.svg") !important;
    content: "";
    display: inline-block;
    height: 22px;
    width: 22px;
}

.shopthelook-product-price {
    .price {
        color: $black;

        @include media-breakpoint-up(lg) {
            color: $white;
        }

        .strike-through,
        .sales {
            @include media-breakpoint-up(lg) {
                color: $white;
            }
        }
    }
}