@import "~base/variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.add-to-wishlist-messages {
    left: 50%;
    position: fixed;
    top: 15%;
    transform: translate(-50%, -50%);
}

.add-to-wishlist-alert {
    animation: fade 5s linear forwards;
    box-shadow: 1px 1px 5px grey;
    padding: 1em;

    @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
    }

    &.show {
        display: block;
    }
}

.wishlistTile {
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: rem-calc(12);
    margin-top: rem-calc(2);
    padding: 0;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(0 16);
    }

    .icon-heart {
        background-image: url("../images/icons/heart-icon.svg");
        content: "";
        display: inline-block;
        height: rem-calc(18);
        width: rem-calc(20);
    }

    .icon-heart-dark {
        background-image: url("../images/icons/wishlist-icon-filled.svg");
        content: "";
        display: inline-block;
        height: rem-calc(18);
        width: rem-calc(20);
    }
}

.wishlistTile {
    .fa-heart {
        color: black;
    }
}
