@import "menu";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.header {
    position: relative;

    /* stylelint-disable */
    .top-bar-mobile {
        background-color: $black;
        padding: rem-calc(8 16);

        a {
            color: $white;
            text-decoration: none;
            font-size: rem-calc(12);
            margin-right: rem-calc(10);
        }

        .popover.popover-bottom {
            position: relative;
            background: transparent;
            color: $white;
        }
    }

    .wishlist-icon {
        font-size: rem-calc(12);
        margin-top: rem-calc(2);
        padding: 0;

        @include media-breakpoint-up(lg) {
            padding: rem-calc(0 16);
        }

        .icon-heart {
            background-image: url("../images/icons/heart-icon-white.svg");
            content: "";
            display: inline-block;
            height: rem-calc(18);
            width: rem-calc(20);

            @include media-breakpoint-up(lg) {
                background-image: url("../images/icons/heart-icon.svg");
            }
        }
    }

    .minicart {
        font-size: rem-calc(12);
        padding: 0;
        top: 0;
        position: relative;

        .minicart-icon {
            background-image: url("../images/icons/cart-icon.svg");
            content: "";
            display: inline-block;
            height: rem-calc(19);
            width: rem-calc(20);
        }

        .minicart-link {
            text-decoration: none;
        }
    }
    /* stylelint-enabled */

    .signup-link {
        font-size: rem-calc(12);
        padding-right: rem-calc(16);

        @include media-breakpoint-down(lg) {
            display: flex;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .c-mobile-hearder {
        .logo-wrapper {
            padding: rem-calc(16 16 0 16);

            .logo-image {
                img {
                    width: 90%;
                }
            }
        }

        .navbar-toggle {
            padding: 0;
            width: rem-calc(48);
            float: left;
            margin: rem-calc(-10 0 0 -9);
            background-color: transparent;
            border: rem-calc(1) solid transparent;

            .icon-bar {
                visibility: visible;
                background-color: $black;
                position: absolute;
                top: 0;
                left: 0;
                width: rem-calc(27);
                height: rem-calc(1);
                transform: rotate(0);
                transition: transform .25s ease;

                &:nth-child(3) {
                    margin-top: rem-calc(20);
                }

                &:nth-child(2) {
                    margin-top: rem-calc(10);
                }
            }
        }

        .search-section {
            padding: rem-calc(16 8);
        }
    }
}

.navbar-header {
    height: auto;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(16 32);
    }

    .user,
    .country-selector,
    .search,
    .minicart {
        display: inline-block;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        height: auto;
    }

    .navbar-toggler {
        font-size: 1.6em;
        width: auto;
    }

    .user {
        position: relative;

        .popover {
            border-radius: 0;
            display: none;
            left: 0;
            padding: 1em;
            position: absolute;
            top: rem-calc(22);
            line-height: 2;

            a {
                margin-bottom: 0.5em;
                white-space: nowrap;
                text-decoration: none;
                display: block;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }
    }
}

.brand {
    width: 100%;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
        padding: rem-calc(24 0);
    }

    img {
        width: 100%;
    }
}

.main-menu {
    background-color: $dark-gray;

    .navbar .close-menu button,
    .navbar .close-button button {
        appearance: none;
        background-color: transparent;
        border: 0;
        color: $black;
        font-size: 14px;

        span {
            font-size: 18px;
        }
    }

    .close-button {
        button {
            margin-top: -10px;
        }
    }
}

.header-banner {
    background-color: $darker-gray;
    color: $white;
    text-align: center;

    .close-button {
        width: 1.5em + $banner-padding * 2;

        .close {
            background-color: #{var(--skin-primary-color-1)};
            color: $white;
            height: 100%;
            opacity: 1;
            width: 100%;
        }
    }

    .content {
        font-size: rem-calc(13);
        padding: rem-calc(7 0);
    }
}

.minicart {
    margin-top: 0;

    .minicart-icon {
        font-size: 1.5em;
    }

    a.minicart-link {
        &:hover {
            text-decoration: none;
        }
    }

    .minicart-quantity {
        background-color: $text-color-1;
        border-radius: 50%;
        width: 1.25em; /* 20/16 */
        height: 1.25em; /* 20/16 */
        line-height: normal;
        display: inline-block;
        text-align: center;
        font-size: 0.8125em; /* 13/16 */
        position: absolute;
        top: rem-calc(-2);
        right: rem-calc(-5);
        color: $white;
    }
}

a.normal {
    color: #{var(--skin-primary-color-1)};
    text-decoration: underline;
}

.slide-up {
    max-height: 100px;
    overflow: hidden;
    transition-duration: 0.5s;
    transition-timing-function: ease-in;

    &.hide {
        margin-bottom: 0;
        margin-top: 0;
        max-height: 0;
        padding-bottom: 0;
        padding-top: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    bottom: 0;
    display: none;
    left: 50%;
    position: fixed;
    text-align: center;
    transform: translate(-50%, 0);
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-bottom: 0.2em;
        margin-top: 0;
        padding-right: 2em;
    }
}

.promo-bar-slider {
    .slick-next,
    .slick-prev {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;

        &::before {
            content: none;
        }
    }

    .slick-next  {
        background-image: url("../images/icons/next.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: rem-calc(15);
        height: rem-calc(15);
        position: absolute;
        right: rem-calc(12);
        top: rem-calc(1);

        &.slick-disabled {
            display: none;
        }
    }

    .promo-text {
        font-size: rem-calc(12);
        font-weight: 700;

        .promo-sub-text {
            font-weight: normal;
        }
    }
}

.login-register-icon {
    width: rem-calc(23);
    height: rem-calc(23);
}

.user-message {
    font-weight: bold;
    font-size: small;
    text-decoration: underline;
}