// Custom fonts
@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 400;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Medium.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 500;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-MediumItalic.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-MediumItalic.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-MediumItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-SemiBold.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-SemiBold.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-SemiBold.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 600;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-SemiBoldItalic.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-SemiBoldItalic.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-SemiBoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 700;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 700;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 800;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-ExtraBold.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-ExtraBold.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-ExtraBold.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 800;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-ExtraBoldItalic.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-ExtraBoldItalic.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-ExtraBoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 900;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Black.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Black.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-Black.woff2") format("woff2");
}

@font-face {
    font-family: "Playfair Display";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 900;
    src:
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.ttf") format("ttf"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.woff") format("woff"),
        url("../../fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 100;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Thin.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Thin.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Thin.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 100;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Thin-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Thin-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Thin-It.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 300;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Light.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Light.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 300;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Light-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Light-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Light-It.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 400;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 400;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-It.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Medium.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Medium.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 500;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Medium-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Medium-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Medium-It.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    src:
        url("../../fonts/ProximaNova/roxima-Nova-Semibold.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Semibold.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Semibold.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 600;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Semibold-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Semibold-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Semibold-It.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 700;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Bold.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Bold.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 700;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Bold-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Bold-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Bold-It.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 800;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Extrabold.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Extrabold.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Extrabold.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 800;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Extrabold-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Extrabold-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Extrabold-It.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 900;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Black.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Black.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Black.woff2") format("woff2");
}

@font-face {
    font-family: "Proxima Nova";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 900;
    src:
        url("../../fonts/ProximaNova/Proxima-Nova-Black-It.ttf") format("ttf"),
        url("../../fonts/ProximaNova/Proxima-Nova-Black-It.woff") format("woff"),
        url("../../fonts/ProximaNova/Proxima-Nova-Black-It.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: normal;
    src:
        url("../../fonts/helvetica/HelveticaNeue.ttf") format("ttf"),
        url("../../fonts/helvetica/HelveticaNeue.woff") format("woff"),
        url("../../fonts/helvetica/HelveticaNeue.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 500;
    src:
        url("../../fonts/helvetica/HelveticaNeue-Medium.ttf") format("ttf"),
        url("../../fonts/helvetica/HelveticaNeue-Medium.woff") format("woff"),
        url("../../fonts/helvetica/HelveticaNeue-Medium.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    src:
        url("../../fonts/helvetica/HelveticaNeue-Bold.ttf") format("ttf"),
        url("../../fonts/helvetica/HelveticaNeue-Bold.woff") format("woff"),
        url("../../fonts/helvetica/HelveticaNeue-Bold.woff2") format("woff2");
}
