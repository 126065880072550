/* Slider */

.slick-slider {
    box-sizing: border-box;
    display: block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-y;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {

    display: none;
    float: left;
    height: auto;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}
