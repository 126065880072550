$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-bottom: 0.3em solid transparent;
    border-left: 0.3 solid transparent;
    border-right: 0.3em solid;
    border-top: 0.3em solid transparent;
    content: "";
    display: inline-block;
    height: 0;
    margin-bottom: 0.125em;
    width: 0;
}

@mixin caret-right() {
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    border-top: 0.3em solid transparent;
    content: "";
    margin-top: 0.55em;
    position: absolute;
    right: 16px;
}
/* stylelint-disable */
.mega-menu-desktop {
    $breakpoint-name: "md";
    $breakpoint-name: "md" !default;
    $breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
    $prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
    $next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index);

    @include media-breakpoint-up($next-breakpoint) {
        .navbar.bg-inverse {
            background-color: transparent !important;
            padding: 0;
            position: relative;
            z-index: 75;

            @include media-breakpoint-up($next-breakpoint) {
                .navbar-nav .nav-item + .nav-item {
                    margin-left: 0;
                }

                .navbar-nav .nav-link {
                    font-weight: 600;
                    padding: rem-calc(8);
                    white-space: nowrap;
                    line-height: rem-calc(24);
                    text-transform: uppercase;
                }
            }
        }

        .mobile-menu {
            display: none;
        }

        .desktop-menu {
            display: block;
        }

        .navbar-header {
            position: relative;
        }

        .navbar-expand-md .navbar-nav.nav-center {
            justify-content: center;
        }

        .navbar-expand-md .navbar-nav.nav-spaced {
            justify-content: space-evenly;
        }

        .navbar-expand-md .navbar-nav.nav-right {
            justify-content: end;
        }

        .nav-item .nav-link,
        .nav-item .nav-link:hover,
        .nav-item .nav-link:focus,
        .nav-item.show .nav-link {
            color: inherit;
            outline: transparent;
        }

        .menu-toggleable-left {
            .menu-group {
                flex: 0 0 100%;
            }

            li > .close-menu {
                margin-right: 0;
                margin-top: -rem-calc(6);
                margin-left: 0;
            }
        }

        .multilevel-dropdown {
            .dropdown-menu {
                top: 90%;
                border: 0;
                border-radius: 0;

                @include media-breakpoint-up(lg) {
                    left: 0;
                    right: 0;
                    top: 38px;

                    &:not(.dropdown-menu-last) {
                        width: 100vw;
                        box-shadow: 0 5px 5px 0 rgba(0,0,0,.15);
                    }
                }

                &.dropdown-menu-last {
                    position: static !important;
                    display: block;
                    padding: 0 0 16px;
                    margin: 0;

                    .dropdown-item:not(:last-of-type) {
                        box-shadow: none;
                        font-size: rem-calc(14);
                    }
                }
            }

            .dropdown-menu > .dropdown > .dropdown-menu {

                @include media-breakpoint-up(lg) {
                    display: block;
                    position: static;
                }
            }

            .dropdown-item {
                padding: 0;

                .dropdown-link {
                    display: block;
                    color: $black;
                }

                &.top-category {
                    font-weight: bold;
                }
            }

            .dropdown-item,
            .nav-item {
                font-family: $font;
                font-size: rem-calc(16);
                box-shadow: inset 0 -1px 0 $black;
                width: auto;

                @include media-breakpoint-up(lg) {
                    font-size: rem-calc(14);
                    box-shadow: none;
                }

                &.member-item {
                    margin: 0;
                    padding: rem-calc(0 16);
                }

                &:hover,
                &:focus {
                    color: inherit;
                    background: none;
                }

                .nav-link,
                .dropdown-link {
                    text-decoration: none;
                    padding: 12px 0;
                    position: relative;

                    @include media-breakpoint-up(lg) {
                        padding: 4px 0;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .main-menu-container {
            position: static !important;

            .dropdown-menu-last {
                box-shadow: none;
                height: auto;
                top: auto;

                .dropdown-item {
                    margin-top: 0;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &.level-3 {
                    .dropdown-item {
                        padding: 0;
                    }
                }
            }

            .dropdown-collapse,
            .dropdown-item {
                font-size: rem-calc(12);
                line-height: 16px;
                -webkit-column-break-inside: avoid;
            }

            .dropdown-collapse {
                color: $black;
            }

            .dropdown-item.nav-subcategory {
                margin-top: 0;
                margin-bottom: rem-calc(32);
            }

            .dropdown-link {
                .icon {
                    display: none;
                }
            }

            .dropdown-menu.show > .dropdown-menu-inner {
                padding: rem-calc(20 46);
                max-width: rem-calc(1440);
                margin: 0 auto;
                -moz-column-count: 4;
                -moz-column-gap: rem-calc(10);
                -webkit-column-count: 4;
                -webkit-column-gap: rem-calc(10);
                column-count: 4;
                column-gap: rem-calc(10);
                margin-top: rem-calc(10);
            }

            .main-nav-link {
                padding: 10px 0;
            }
        }

        .navbar,
        .nav-item {
            position: static;

        }
    }
}

.toggle-menu-mobile {
    $breakpoint-name: "sm";
    $breakpoint-name: "sm" !default;
    $breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
    $prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
    $next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

    $slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

    @include media-breakpoint-down(md) {
        .menu-toggleable-left.navbar-toggleable-md {
            bottom: 0;
            display: block;
            left: -100%;
            max-width: 100%;
            position: fixed;
            top: 0;
            transition: $slide-out-animation;

            &.in {
                left: 0;
                max-width: 70%;
            }
        }


        .mobile-menu {
            display: block;
        }

        .desktop-menu {
            display: none;
        }
    }

    .navbar.bg-inverse {
        background-color: transparent !important;
        padding: 0;

        @include media-breakpoint-up(lg) {
            .navbar-nav .nav-item + .nav-item {
                margin-left: 0;
            }

            .navbar-nav .nav-link {
                padding: rem-calc(7 16);
                white-space: nowrap;

                &.dropdown-toggle::after {
                    display: none;
                }
            }
        }

        .navbar-nav {
            justify-content: center;
        }
    }

    .navbar-expand-md {
        display: flex;

        @include media-breakpoint-up(sm) {
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: center;
        }
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: column;
        display: flex;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    .navbar-expand-md .navbar-nav.nav-center {
        justify-content: center;
    }

    .navbar-expand-md .navbar-nav.nav-spaced {
        justify-content: space-evenly;
    }

    .navbar-expand-md .navbar-nav.nav-right {
        justify-content: end;
    }

    .nav-item .nav-link:hover,
    .nav-item .nav-link:focus,
    .nav-item.show .nav-link {
        background-color: $white;
        color: $menu-link;
    }

    @include media-breakpoint-up(lg) {
        .nav-item > .nav-link {
            color: $white;
        }
    }

    .main-menu.menu-toggleable-left {

        @include media-breakpoint-down(md) {
            background-color: $white;
            z-index: 100;
        }
    }

    .menu-toggleable-left {

        @include media-breakpoint-down(md) {
            .bg-inverse {
                background-color: white !important;
                color: $grey7;
            }
        }

        .close-menu {
            border-bottom: 1px solid $grey3;
            padding: 15px;
            width: 100%;
            font-size: 12px;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        li > .close-menu {
            margin-left: 0;
            margin-right: 0;
            margin-top: -0.6rem;
        }

        &.in {

            @include media-breakpoint-down(md) {
                margin-right: 1.25em; /* 20/16 */
                right: 0;
            }

            @include media-breakpoint-down(md) {
                .nav-item + .nav-item {
                    border-top: 1px solid $grey2;
                }

                .dropdown {
                    display: block;
                    position: static;
                    word-wrap: normal;
                    line-height: 17px;
                }

                .dropdown-toggle {
                    padding-left: rem-calc(16);

                    &::after {
                        @include caret-right();
                    }
                }

                .nav-item .nav-link {
                    padding-left: rem-calc(16);
                    font-size: 12px;
                }

                .show > .dropdown-menu {
                    left: 0;
                }

                .dropdown-menu {
                    border: 0 none;
                    display: block;
                    height: 100%;
                    left: -100%;
                    position: absolute;
                    top: 0;
                    transition: $slide-out-animation;
                    width: 100%;
                }
            }
        }

        .menu-group {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .multilevel-dropdown {
        .dropdown-menu {
            border: 0;
            border-radius: 0;
            top: 90%;

            @include media-breakpoint-up(lg) {
                box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
            }
        }

        .dropdown-menu-inner {
            padding: 0;
            font-size: 12px;
        }

        .dropdown-item.dropdown > .dropdown-toggle::after {
            @include caret-right();
            padding-right: rem-calc(16);
        }

        .dropdown-menu > .dropdown > .dropdown-menu {

            @include media-breakpoint-up(lg) {
                left: 99%;
                top: -0.65em;
            }
        }

        .navbar > .close-menu > .back {
            display: none;
        }

        .close-menu .back {
            .caret-left {
                @include caret-left();
                margin-bottom: 0;
            }
        }

        .dropdown-item {
            padding: 0 16px;

            .dropdown-link {
                display: block;
                padding: 0.425em 5em 0.425em 0;
                font-size: 12px;

                &.level-2 {
                    &::after {
                        @include caret-right();
                    }
                }
            }

            + .dropdown-item {
                border-top: 1px solid $close-menu-bg;
            }

            &.top-category {
                font-weight: bold;

                > .nav-link {
                    padding-left: 0;
                }
            }

            .level-3 {
                .level-2 {
                    &::after {
                        content: unset;
                    }
                }
            }
        }
    }
}

.navbar {
    .container {
        @include media-breakpoint-up(lg) {
            gap: 15px;
            width: 95%;
        }
    }
}