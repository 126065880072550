@import "../variables";

.hero {
    background-position: 50%;
    background-size: cover;
    height: 25vw;
    position: relative;
    /* stylelint-disable */
    h1.page-title {
        margin: -1em 0 0;
        top: 50%;
    }
    /* stylelint-enabled */

    .page-title,
    .page-title::before {
        background-color: $primary;
    }
}

.slant-down {

    @include media-breakpoint-up(sm) {
        &::after {
            display: none;
        }
    }
}

.slant-up {

    @include media-breakpoint-up(sm) {
        &::after {
            border: 0 solid transparent;
            border-left-width: 0;
            border-right-width: 90vw;
            border-top: 4vw solid $body-bg;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            top: 0;
            width: 0;
        }
    }
}

h1.page-title {
    background-color: #{var(--skin-primary-color-1)};
    color: $white;
    display: inline-block;
    font-size: 1.5rem;
    margin: 0.9375em 0;
    padding: 0.3125em 0.625em 0.3125em calc($grid-gutter-width / 2);
    position: relative;
    text-shadow: 2px 2px 2px black;

    @include media-breakpoint-up(sm) { font-size: 2rem; }

    @include media-breakpoint-up(md) { font-size: 3rem; }

    .title-text {

        @include media-breakpoint-up(md){
            margin-left: rem-calc(90);
        }
    }
}
