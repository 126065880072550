@import "collapsibleItem";
@import "toastMessage";

/* stylelint-disable */

footer {
    background-color: $footer-backgorund;
    padding-bottom: 1.25em;
    padding-top: 1.25em; /* 20/16 */

    @include media-breakpoint-down(md) {
        padding: rem-calc(16);
    }

    h2 {
        font-size: rem-calc(14);
        font-weight: bold;
        margin-bottom: 0.625em;
        margin-top: 0.625em;
        text-transform: uppercase;
        line-height: 2.5em; /* 40/16 */
    }

    .collapsible-md {
        .title {
            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    .social {

        @include clearfix;

        h2 {
            margin-top: 0;
        }
    }

    .footer-container {
        text-transform: uppercase;
        font-size: rem-calc(12);

        @include media-breakpoint-down(md) {
            margin-bottom: rem-calc(16);
        }

        .footer-item.collapsible-xs button {
            padding: 0;
        }

        a {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $link-hover;
            }
        }
    }

    .social-links {

        @include clearfix;

        float: left;

        @include media-breakpoint-down(xs) {
            width: 85%;
        }

        li {
            float: left;
            margin: 0;

            @include media-breakpoint-down(xs) {
                text-align: center;
                width: 20%;
            }
        }

        a {
            font-size: 2.25em;

            &:hover {
                text-decoration: none;
            }
        }

        .fa:hover::before {
            color: $link-hover;
        }
    }

    .store {

        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid $dark-gray;

            .content {
                display: none;
            }

            h2 {
                @include clearfix;

                &::after {
                    content: "\f041";
                    float: right;
                    font-family: "FontAwesome";
                }
            }
        }
    }

    .content {
        li {
            height: 1.875rem;
            font-size: rem-calc(12);
            text-transform: capitalize;
        }
    }

    .copyright,
    .postscript {
        font-size: rem-calc(12);
    }

    .copyright {
        margin-bottom: 0.625em;
    }

    .back-to-top {
        appearance: none;
        background-color: transparent;
        border: 0;
        margin: 0.1em 0.313em;
        padding: 0;

        i {
            &.fa-arrow-up {
                color: rgba(0, 0, 0, 0.7);
                bottom: rem-calc(-5);
            }

            &.fa-circle {
                text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            }
        }

        .fa-stack {
            border: 1px solid $dark-gray;
            border-radius: 100%;
            height: rem-calc(36);
            width: rem-calc(36);
        }

        &:hover {
            .fa-stack {
                border: 1px solid $link-hover;
            }

            i {
                color: $link-hover;
            }
        }
    }

    .back-to-top-container {
        position: relative;
        bottom: rem-calc(2);
        right: 0;
        display: inline-block;
    }
}

.footer-item {

    @include media-breakpoint-down(md) {
        border-bottom: 1px solid $dark-gray;
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.subscription-section {
    background-color: $footer-backgorund;

    .home-email-signup  {
        background-color: $footer-backgorund;
        color: var(--text-black);;
        font-family: var(--font-primary);
        padding: rem-calc(24 16);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(40 0 0);
            margin-top: rem-calC(32);
        }

        @include media-breakpoint-up(xl) {
            padding: rem-calc(40 100 0 100);
        }

        .title-column {
            width: 100%;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
                width: 100%;
            }
        }

        p {
            margin: 0;
        }

        .title {
            font-size: 24px;
            font-weight: bold;

            @include media-breakpoint-down(md) {
                font-size: var(--text-base);
            }
        }

        .description {
            color: var(--text-black);
            font-size: var(--text-xs);
            font-weight: normal;
            padding: rem-calc(10 0);
            line-height: 1.5;
        }
    }

    .newsletter-margins-enabled {
        margin-top: 30px;

        @include media-breakpoint-up(xl) {
            margin-top: 40px;
        }
    }


    .form-column {
        width: 50%;

        @include media-breakpoint-down(md) {
            margin-left: 0;
            text-align: start;
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            margin: auto;
            text-align: center;
        }

        input,
        .newsletter-subscription-button {
            border-radius: 4px;
        }

        input {
            // margin: auto;
            height: 100%;
            padding: 14px 16px;
            // width: 66%;
            // width: 44%;
        }

        .newsletter-subscription-button {
            height: fit-content;
            max-width: 128px;
            overflow: hidden;
            padding: 14px 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: fit-content;
        }
    }

    .form-column form {
        display: flex;
        flex-wrap: nowrap;

        @include media-breakpoint-up(lg) {
            flex-wrap: unset;
        }
    }

    .form-column .form-group {
        position: relative;
        width: 66%;

        @include media-breakpoint-down(sm) {
            width: 58%;
        }
    }

    .form-column .invalid-feedback {
        bottom: -1.5rem;
        color: #fff;
        display: none;
        font-size: 80%;
        left: 0px;
        margin-top: 0.25rem;
        position: absolute;
        width: 100%;
    }

    .form-control:invalid + .invalid-feedback {
        display: block;
        text-align: left;
    }


    .form-column {
        input,
        .newsletter-subscription-button {
            font-size: var(--text-sm);
            line-height: 20px;
        }

        .newsletter-subscription-button {
            background: var(--background-primary);
            color: var(--text-black);
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}

.social-links {
    .fa {
        text-decoration: none;
        margin: rem-calc(0 10 0 0);
    }

    .fa::before{
        border: 1px solid;
        padding: rem-calc(8);
        border-radius: 100%;
        width: rem-calc(36);
        height: rem-calc(36);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem-calc(20);
        color: var(--text-black);
        transition: all .13s ease-in;
    }
}

.instagram-btn {
    margin-top: rem-calc(16);
}

.newsletter-form {
    margin-right: 10px;
}

.fa-shielded {
    background-image: url("../images/shielded-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 1px solid;
    padding: rem-calc(8);
    border-radius: 100%;
    width: rem-calc(36);
    height: rem-calc(36);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem-calc(20);
    color: var(--text-black);
    transition: all .13s ease-in;


    &:hover {
        background-image: url("../images/shielded-icon-hover.png");
    }
}
