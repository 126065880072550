.gift-registry-banner {
    background-image: url("../images/giftcard_banner.jpg");
    height: 56vw;

    .banner-with-overlay-overlay {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.45);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    .gift-card-hero-overlay {
        color: $white;
        text-align: center;

        .button-down-arrow {
            background: url("../images/icons/icon-down-arrow-large.svg") no-repeat center center;
            background-position: center center;
            background-size: cover;
            color: rgba(0, 0, 0, 0);
            display: block;
            height: 40px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            width: 38px;
        }
    }

    .gift-card-hero-italic {
        font-size: 54px;
        font-style: italic;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            font-size: 28px;
            margin-bottom: 0;
        }
    }
    
    .gift-card-hero {
        font-size: 74px;
        font-weight: 700;
        line-height: 85px;
        margin-bottom: 25px;
        max-width: 500px;
        width: 100%;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 15px;
        }
    }

    p {
        font-size: 13px;
        letter-spacing: 0.5px;
        line-height: 1.5;

        a {
            color: #e0cb95;
            text-decoration: none;
        }
    }
}

.gift-card-selection {
    padding: 120px 0;

    .container {
        max-width: 1072px;
        padding: 0 16px;
    }

    h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4em;
    }
}

.gift-card-selection-type {
    &.gift-card-physical {
        @include media-breakpoint-down(md) {
            margin-bottom: 50px;
        }

        div {
            background-color: $bg-solitude-blue;
        }
    }

    &.gift-card-digital {
        div {
            background-color: rgba(161, 125, 35, 0.1);
        }
    }
    
    div {
        padding: 30px 40px 35px;
        text-align: center;

        .btn {
            background: none;
            border: 0;
            border-bottom: 2px solid $blue;
            border-top: 2px solid $blue;
            color: $blue;
            font-weight: 700;
            height: auto;
            letter-spacing: 1.5px;
            line-height: 1;
            padding: 15px 20px;
            text-transform: uppercase;

            &:hover {
                border-bottom: 2px solid $border-button-default;
                border-top: 2px solid $border-button-default;
                color: $text-color-1;
            }
        }
    }

    .gift-card-selection-content {
        color: $text-secondary;
        font-size: 13px;
        letter-spacing: 0.5px;
        line-height: 1.5;
    }

}

.gift-card-balance {
    &.gift-card-landing {
        background-color: $bg-solitude-blue;
        color: $blue;
        padding: 130px 0 140px;

        @include media-breakpoint-down(md) {
            padding: 50px 0 60px;
        }
    }

    .gift-card-balance-block {
        max-width: 1072px;
        padding: 0 16px;
    }

    .gift-card-balance-form {
        @include media-breakpoint-down(md) {
            order: 2;
        }
    }

    .gift-card-balance-image {
        max-width: 520px;
        width: 100%;
    }

    .form-control {
        background-color: transparent;
        border: 1px solid $blue;
        color: $blue;
        height: 34px;
        line-height: 1.42857143;
        padding: 6px 12px;
        width: 100%;
    }

    .form-control-label {
        color: $blue;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1px;
    }

    .get-balance-btn {
        background: none;
        border: 0;
        border-bottom: 2px solid $blue;
        border-top: 2px solid $blue;
        color: $blue;
        font-weight: 700;
        height: auto;
        letter-spacing: 1.5px;
        line-height: 1;
        padding: 15px 20px;
        text-transform: uppercase;

        &:hover {
            background: none;
            border-bottom: 2px solid $border-button-default;
            border-top: 2px solid $border-button-default;
            color: $text-color-1;
        }
    }
}

.img-responsive {
    margin: auto;
    width: 100%;
}

.gift-card-content-section {
    padding: 120px 0;

    @include media-breakpoint-down(md) {
        padding: 60px 0;
    }

    .gift-card-content {
        max-width: 1072px;
        padding: 0 16px;
    }
    
    .gift-card-content-text {
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }

        p {
            color: $text-secondary;
            font-size: 13px;
            letter-spacing: 0.5px;
            line-height: 1.5;
            margin-bottom: 10px;
        }
    }
}

.gift-card-shopping {
    margin-top: 70px;

    @include media-breakpoint-down(md) {
        margin-top: 30px;
    }

    h1 {
        font-size: 34px;
        letter-spacing: 0;
        line-height: 37px;
        margin-bottom: 17px;
        
        @include media-breakpoint-down(md) {
            line-height: 31px;
            margin-top: 28px;
        }
    }

    h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 10px;
    }

    p {
        color: $text-secondary;
        font-size: 13px;
        letter-spacing: 0.5px;
        line-height: 1.5;
    }

    .card-type-selection {
        flex-direction: row;
        
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    .giftcard-values {
        .fixed-giftcard-value {
            border: 1px $grey3 solid;
            border-radius: 2px;
            color: $text-secondary;
            font-size: 22px;
            height: 74px;
            line-height: 71px;
            list-style: none;
            margin-right: 20px;
            width: 81px;
            
            @include media-breakpoint-down(md) {
                font-size: 18px;
                height: 60px;
                line-height: 60px;
                margin-right: 8px;
                width: 60px;
            }

            &:hover {
                border: 2px $border-button-default solid;
                line-height: 69px;
            }
        }

        .fixed-giftcard-value.selected {
            border: 2px $border-button-default solid;
            line-height: 69px;

            @include media-breakpoint-down(md) {
                line-height: 59px;
            }
        }
    }
    
    .custom-gift-card-value {
        margin: 20px 0 30px;
        min-width: 100%;

        .control-label {
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 1px;
            margin-right: 7px;
        }

        .gift-card-amount-value {
            font-size: 14px;
            height: 34px;
            margin-right: 13px;
            max-width: 105px;

            &::placeholder {
                color: $text-secondary;
                font-size: 11px;
                line-height: 22px;
            }
        }
        
        .help-text {
            color: $text-secondary;
            font-size: 11px;
            line-height: 22px;
        }
    }

    .custom-value-wrapper {
        align-items: center;
        flex-direction: row;
        
        @include media-breakpoint-down(md) {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .voucher-digital,
    .voucher-physical {
        .control-label {
            color: $text-secondary;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 19px;
        }

        .form-control {
            height: 34px;
            min-width: 40px;
            padding: 6px 12px;

            &.receipt-message {
                font-size: 12px;
                height: auto;
                padding: 22px;
            }
        }

        .product-quantity {
            /* stylelint-disable-next-line */
            -moz-appearance: none;
            /* stylelint-disable-next-line */
            -webkit-appearance: none;
            appearance: none;
            background: none;
            background-color: $white;
            border-color: $grey3;
            border-radius: 0;
            font-style: italic;
            height: 34px;
            margin-left: 8px;
            padding: 0 0 0 13px;
            width: 34px;
            
            @include media-breakpoint-down(md) {
                margin-left: 60px;
            }
        }
    }

    .gift-card-heading {
        margin-bottom: 30px;
    }

    .checkbox {
        color: $text-secondary;
        font-size: 13px;
        margin: 10px 0 20px;

        a {
            color: $black;
            font-size: 14px;
            text-decoration: none;
        }
    }

    .gift-card-submit {
        background: none;
        border: 0;
        border-bottom: 2px solid $border-button-default;
        border-top: 2px solid $border-button-default;
        color: $border-button-default;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.5px;
        margin: 15px 0 95px;
        padding: 15px 20px;
        
        @include media-breakpoint-down(md) {
            margin: 15px 0 20px;
        }

        &:hover {
            background: none;
            border: 0;
            border-bottom: 2px solid $blue;
            border-top: 2px solid $blue;
            color: $blue;
        }
    }
}

.card-type-option {
    border: 1px solid $bg-secondary;
    cursor: pointer;
    padding: 20px 30px;
    position: relative;
    width: 50%;

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    &:hover {
        border: 1px solid $border-button-default;
    }

    &.selected {
        background: $bg-secondary;
    }

    .checkmark {
        background-color: $white;
        border: 1px solid $bg-secondary;
        border-radius: 50%;
        height: 30px;
        padding-left: 20px;
        position: relative;
        width: 30px;

        &::after {
            background: url("../images/icons/icon-tick.svg") no-repeat center center;
            background-size: 30px 30px;
            bottom: 0;
            content: "";
            height: 30px;
            left: 0;
            position: absolute;
            right: 0;
            top: -1px;
            width: 30px;
        }
    }

    input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        
        &:checked~.checkmark {
            background-color: $border-button-default;
            border: 1px solid $border-button-default;
        }
    }

    span {
        padding-left: 20px;
    }
}

.faq-title {
    margin: 20px 0 0;
}

.faq-accordion {
    margin: 15px 0 30px;

    .panel-body {
        color: #2b2b2b;
        font-size: 13px;
        letter-spacing: 0.5px;
        line-height: 19px;
        padding: 0 0 17px;
    }
}

.panel-title {
    border-bottom: 1px solid #979797;
    color: $grey8;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    padding: 0 0 12px;
}

.panel-link {
    text-decoration: none;

    &:hover {
        color: $grey8 !important;
    }

    .plus-btn {
        display: inline-block;
        font-size: 15px;
        font-weight: 700;
        height: 5px;
        line-height: 20px;
        margin-right: 10px;
        width: 5px;

        &::before {
            content: "-";
        }
    }

    &.collapsed {
        .plus-btn {
            &::before {
                content: "+";
            }
        }
    }
}

