@import "~base/utilities/swatch";
@import "productCommon";

.quick-view-dialog,
.choose-bonus-product-dialog {
    max-width: 56.25em;

    .selectable-bonus-product-line-item {
        margin-bottom: 0.2em;
        margin-top: 0.2em;
        padding-bottom: 0.3em;
        padding-top: 0.3em;
    }
    /* stylelint-disable-next-line */
    .beenSelected {
        background-color: $grey2;
    }

    .modal-header {
        background-color: transparent;
        border-bottom: 1px solid $grey3;
        color: #000;
        opacity: 1;
        padding: 0 16px 16px;
        /* stylelint-disable */

        .full-pdp-link {
            color: #{var(--skin-primary-color-1)};
            font-size: rem-calc(16);
        }

        .close {
            font-size: 2rem;
            line-height: 1.5rem;
            right: 30px;
            top: 30px;
            padding: 0;
        }
    }

    .modal-title {
        font-size: 1em;
    }

    .product-name {
        font-size: 1.875em;
    }

    .color-attribute {
        @include swatch-square();
    }

    a[disabled] .swatch-circle {
        cursor: not-allowed;

        &.color-value.selected::after {
            background-color: $gray-700;
        }
    }

    .availablity-container {
        text-align: right;
    }

    .availablity-container,
    .size-chart {
        margin-top: 0.938em;
    }

    .modal-content {
        border: none;
        border-radius: 0;
        padding: 16px 0;
    }

    .modal-body {
        max-height: 28.125em; /* 450/16 */
        overflow-y: auto;
        padding: 16px 0;
    }

    button.close {
        font-size: 1.25em;
    }

    .modal-footer {
        background-color: $white;
        border: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 16px 16px;

        .prices .price {
            font-size: 1.6em;
        }
    }

    .prices .sales {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .promotions {
        color: $black;
        text-align: left;
    }

    .bonus-summary {
        @include media-breakpoint-down(sm) {
            font-size: 0.625em;
        }

        .bonus-product-name {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
    }

    .pre-cart-products {
        margin-right: 0.125em;
    }

    .color-attribute {
        background: none;
        border: 0;
        padding: 0;
    }

    .non-input-label {
        display: block;
        margin-bottom: 0.5rem;
    }

    .primary-images {
        padding-left: 0;
    }

    .full-pdp-link {
        color: $link-color !important;
        font-size: rem-calc(14);
        letter-spacing: 1px;
        padding: 0;
        text-decoration: underline;

        &:hover {
            color: $blue !important;
        }
    }
}
