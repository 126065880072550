@import "toastMessage";

.attribute {
    margin-top: 0.938em;

    label {
        display: block;
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {

    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }
}

.prices-add-to-cart-actions {
    bottom: 0;
    left: 0;
    margin: 0 0 24px;
    padding-left: 0;
    padding-right: 0;
    position: static;
    width: 100%;
    z-index: 50;

    @include media-breakpoint-down(xs) {
        background-color: transparent;
        box-shadow: unset;
    }

    @include media-breakpoint-up(sm) {
        padding: 0 0.9375em;
    }

    .price {
        text-align: center;
    }

    .pdp-price {
        .price {
            text-align: left;
        }
    }
}

.prices {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    text-align: center;
}

.cart-and-ipay {
    text-align: center;

    @include media-breakpoint-only(xs) {
        padding-bottom: 0;

        .btn {
            display: block;
            margin: 0;
            width: 100%;
        }
    }
}

.add-to-cart-messages {
    @include toast-message();
}

.add-to-basket-alert {
    @include toast-alert();
}

.simple-quantity {
    margin-top: 1em;
}

.main-attributes {
    margin-top: 1em;
}

.size-chart {
    margin-top: 1.071em;
}

div.availability { /* stylelint-disable */
    margin-top: 1.071em;
}

.bundle-item {
    border-bottom: 1px solid $hr-border-color;
    padding-bottom: 1em;

    &:last-child {
        border-bottom: 0;
    }
}

.container.product-detail {
    margin-bottom: 32px;
    margin-top: 32px;
}

.product-option:not(:first-child) {
    margin-top: 1.071em;
}

.product-badge-wrap {
    position: absolute;
    top: 0;
    width: 25%;

    .badge-container {
        margin: rem-calc(16 0);

        .text-badge {
            background-color: $primary;
            color: $white;
            padding: rem-calc(5 10);
            font-size: rem-calc(14);
        }

        &.image-wrap {
            width: 50%;

            .image-badge {
                width: rem-calc(85);
                height: rem-calc(22);
            }
        }
    }
}

.fa-star,
.fa-star-half-empty,
.fa-star-half-full,
.fa-star-half-o {
    &::before {
        color: $primary;
    }
}